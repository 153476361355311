/* eslint-disable */
import { Button, Checkbox, Col, Input, Popover, Row, Tooltip } from "antd";
import { FC, useContext, useEffect, useState } from "react";
import "./styles.css";
import { ChevronRight } from "react-feather";
import { t } from "i18next";
import { HomeOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { CustomerContext, UserContext } from "src/config/userContext";
import papiService from "src/services/papi/papiService";
import { useNavigate } from "react-router-dom";

interface CustomerDropdownProps {
  collapsed?: boolean;
}

const ContactUs: FC<CustomerDropdownProps> = ({ collapsed }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [customerPopoverVisible, setCustomerPopoverVisible] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { customer, customerUpdate } = useContext(CustomerContext);
  const [sentinelChecked, setSentinelChecked] = useState(true);

  useEffect(() => {
    if (user?.user?.role === "ADMIN") {
      setIsAdmin(true);
    }
  });

  if (collapsed) {
    return null;
  }

  async function startOnBoarding() {
    navigate("/onboarding");
  }

  async function handleCreateCustomer() {
    if (customerName.trim()) {
      setCustomerPopoverVisible(false);
      setDropdownVisible(false);
      const userEmail = sentinelChecked ? [user?.user?.email] : [];
      const newCustomer = await papiService.createCustomer(customerName, userEmail);
      console.log("Customer created: ", newCustomer);
      setCustomerName("");
      window.location.reload();
      customerUpdate(newCustomer);
    }
  }

  const content = (
    <Col style={{ width: "200px" }}>
      <div className="contact-us-popover-item">
        <Tooltip title="search">
          <Button type="primary" shape="circle" icon={<PhoneOutlined />} />
        </Tooltip>
        <div className="contact-us-popover-item-text">
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>Phone</span>
          <span style={{ fontSize: "12px" }}>(+34) 933803348</span>
        </div>
      </div>

      <a href="mailto:soporte@bookline.ai" style={{ color: "inherit", textDecoration: "none" }}>
        <div className="contact-us-popover-item">
          <Tooltip title="search">
            <Button type="primary" shape="circle" icon={<MailOutlined />} />
          </Tooltip>
          <div className="contact-us-popover-item-text">
            <span style={{ fontWeight: "bold", fontSize: "14px" }}>Email</span>
            <span style={{ fontSize: "12px" }}>soporte@bookline.ai</span>
          </div>
        </div>
      </a>

      <div className="contact-us-popover-item">
        <Tooltip title="search">
          <Button type="primary" shape="circle" icon={<HomeOutlined />} />
        </Tooltip>
        <div className="contact-us-popover-item-text">
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>WhatsApp</span>
          <a href="https://wa.me/34633803348" target="_blank" rel="noopener noreferrer" style={{ fontSize: "12px", color: "inherit", textDecoration: "none" }}>
            933 803 348
          </a>
        </div>
      </div>
    </Col>
  );

  const customerContent = (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }} onClick={(e) => e.stopPropagation()}>
      <Input placeholder="Enter customer name" value={customerName} onChange={(e) => setCustomerName(e.target.value)} onClick={(e) => e.stopPropagation()} />
      <Checkbox checked={sentinelChecked} onChange={(e) => setSentinelChecked(e.target.checked)}>Be aware of emails send to the client</Checkbox>
      <Button type="primary" onClick={handleCreateCustomer}>
        Confirm
      </Button>
    </div>
  );

  return (
    !collapsed && (
      <div className="contact-us-wrapper">
        {!isAdmin ? (
          <>
            <h3 style={{ color: "white" }}>{t("need_help")}</h3>
            <Popover content={content} trigger="click" placement="right">
              <Button type="primary">
                <span style={{ display: "flex", alignItems: "center", gap: "8px", fontWeight: "bold" }}>
                  {t("contact_us")}
                  <ChevronRight />
                </span>
              </Button>
            </Popover>
          </>
        ) : (
          <>
            <Popover
              placement="rightTop"
              content={customerContent}
              title="Create Customer"
              trigger="click"
              open={customerPopoverVisible}
              onOpenChange={(open) => {
                setCustomerPopoverVisible(open);
                if (open) {
                  setDropdownVisible(true);
                }
              }}
              getPopupContainer={(trigger) => trigger.parentElement || document.body}
            >
              <Button
                type="primary"
                className="custom-dropdown-create-customer"
                onClick={(event) => {
                  event.stopPropagation();
                  setCustomerPopoverVisible(true);
                }}
              >
                Create Customer
              </Button>
            </Popover>
            <Button type="primary" onClick={() => startOnBoarding()}>
              Bot Onboarding
            </Button>
          </>
        )}
      </div>
    )
  );
};

export default ContactUs;
