/* eslint-disable */
/* eslint-disable import/no-unresolved */
import { createContext, useState } from "react";

const ShowSidebarContext = createContext({
  showSidebar: false,
  showSidebarFixed: false,
  updateSidebar: (updateSidebar: boolean, updateSidebar1: boolean) => {},
});

function ShowSidebarProviderWrapper(props: any) {
  const [showSidebar, setShowSidebar] = useState(false);
  const [showSidebarFixed, setShowSidebarFixed] = useState(false);

  const updateSidebar = (bool: any, bool1: any) => {
    setShowSidebar(bool);
    setShowSidebarFixed(bool1);
  };

  const { children } = props;

  return <ShowSidebarContext.Provider value={{ showSidebar, updateSidebar, showSidebarFixed }}>{children}</ShowSidebarContext.Provider>;
}

const UserContext = createContext({
  user: {
    user: {
      auth_uid: "defaultUid",
      email: "default@email.com",
      id: "defaultId",
      name: "defaultName",
      permissions: [],
      phone: {},
      role: "CLIENT",
      sip_settings: {},
      language: "SPANISH",
      allows_marketing: false
    },
    customers: [],
  },
  userUpdate: (userName: any) => {},
});

function UserProviderWrapper(props: any) {
  const [user, setUser] = useState({
    user: {
      auth_uid: "defaultUid",
      email: "default@email.com",
      id: "defaultId",
      name: "defaultName",
      permissions: [],
      phone: {},
      role: "CLIENT",
      sip_settings: {},
      language: "SPANISH",
      allows_marketing: false
    },
    customers: [],
  });

  const userUpdate = (userPapi: any) => {
    setUser(userPapi);
    sessionStorage.setItem("papiUser", JSON.stringify(userPapi));
  };

  const { children } = props;

  return <UserContext.Provider value={{ user, userUpdate }}>{children}</UserContext.Provider>;
}

const CustomerContext = createContext({
  customer: {
    id: "defaultCustomer",
    name: "defaultName",
    accounts: [{}],
    crm_id: "",
    trial_end_date: "",
    trial_start_date: "",
    onboarding: {
      config: {},
      status: "",
      entry_step: "",
    },
    mixpanel_reports: [],
    sentinel_mails: []
  },

  customerUpdate: (customer: any) => {},
});

function CustomerProviderWrapper(props: any) {
  const [customer, setCustomer] = useState({
    id: "defaultCustomer",
    name: "defaultName",
    accounts: [{}],
    trial_end_date:"",
    trial_start_date: "",
    crm_id: "",
    onboarding: { config: {}, status: "", entry_step: "" },
    mixpanel_reports: [],
    sentinel_mails: []
  });

  const customerUpdate = (custom: any) => {
    setCustomer(custom);
    sessionStorage.setItem("customerSelected", JSON.stringify(custom));
  };
  const { children } = props;

  return <CustomerContext.Provider value={{ customer, customerUpdate }}>{children}</CustomerContext.Provider>;
}

export { UserContext, UserProviderWrapper, ShowSidebarProviderWrapper, ShowSidebarContext, CustomerContext, CustomerProviderWrapper };
