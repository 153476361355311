/* eslint-disable */
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, confirmPasswordReset, signOut, deleteUser, User } from "firebase/auth";
import { query, collection, where, orderBy, limit, getFirestore, onSnapshot } from "firebase/firestore";
import { getStorage } from "firebase/storage";

let firebaseConfig = {
  apiKey: "AIzaSyCda07Fb4WeT3-zXmYdYti28x8iNuKutlg",
  authDomain: "bookline-dev.firebaseapp.com",
  projectId: "bookline-dev",
  storageBucket: "bookline-dev.appspot.com",
  messagingSenderId: "535747777345",
  appId: "1:535747777345:web:a59f61e89f7cdccbd187c0",
  measurementId: "G-TRZWNFVDNP",
};
if (process.env.REACT_APP_ENVIRONMENT === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyAJjD0zlh6wb-7B5Zh598jrMKS4eadapp4",
    authDomain: "bookline-pro.firebaseapp.com",
    projectId: "bookline-pro",
    storageBucket: "bookline-pro.appspot.com",
    messagingSenderId: "533389742730",
    appId: "1:533389742730:web:84a9df8ea53ade2ffdd373",
    measurementId: "",
  };
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);


// Inicializa Firestore para la app secundaria
const dbWisecrack = getFirestore(app, "wisecrack");

const logInWithEmailAndPassword = async (email: string, password: string) => {
  const resp = await signInWithEmailAndPassword(auth, email, password);
  return resp;
};

const registerWithEmailAndPassword = async (email: string, password: string) => {
  const res = await createUserWithEmailAndPassword(auth, email, password);
  const { user } = res;
  return user;
};

const sendPasswordReset = async (email: string) => {
  const res = await sendPasswordResetEmail(auth, email);
  return res;
};

const confirmPasswordResetURL = async (code: string, newPass: string) => {
  try {
    await confirmPasswordReset(auth, code, newPass);
    return true;
  } catch (err: any) {
    console.error(err);
    const err1: string = err.message;
    return err1;
  }
};

const logout = () => {
  signOut(auth)
    .then(() => {
      sessionStorage.clear();
    })
    .catch(() => {});
};

export { auth, logInWithEmailAndPassword, registerWithEmailAndPassword, logout, app, sendPasswordReset, confirmPasswordResetURL, query, collection, where, orderBy, limit, firestore, dbWisecrack, onSnapshot, storage };
