/* eslint-disable */
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ToastProvider } from "react-toast-notifications";
import { CustomerProviderWrapper, ShowSidebarProviderWrapper, UserProviderWrapper } from "src/config/userContext";

import App from "./App";
import { AuthProvider } from "./config/provider";
import PersistProvider from "./redux/providers/persist-provider";
import ThemeProvider from "./redux/providers/theme-provider";
import { store } from "./redux/store";
import * as Sentry from "@sentry/react";
import "./i18n";
import "./global.css";

const link = document.createElement('link');
link.href = 'https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap';
link.rel = 'stylesheet';
document.head.appendChild(link);

Sentry.init({
  dsn: "https://2ea2c3cfaef54fe49cb61b3027690d68@o4505193558441984.ingest.sentry.io/4505193566109696",
  environment: process.env.REACT_APP_ENVIRONMENT,
});

ReactDOM.render(
  <Provider store={store}>
    <PersistProvider>
      <ThemeProvider>
          <UserProviderWrapper>
            <CustomerProviderWrapper>
              <AuthProvider>
                <ToastProvider>
                  <App />
                </ToastProvider>
              </AuthProvider>
            </CustomerProviderWrapper>
          </UserProviderWrapper>
      </ThemeProvider>
    </PersistProvider>
  </Provider>,
  document.getElementById("root")
);
