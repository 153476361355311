/* eslint-disable */
import { FC, useContext, useEffect, useState } from "react";
import { CustomerContext, UserContext } from "src/config/userContext";
import { Dropdown, Tooltip, MenuProps, Button, Popover, Input } from "antd";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { ChevronDown } from "react-feather";

interface CustomerDropdownProps {
  collapsed?: boolean;
}

const CustomerDropdown: FC<CustomerDropdownProps> = ({ collapsed }) => {
  const { user } = useContext(UserContext);
  const { customer, customerUpdate } = useContext(CustomerContext);
  const { t } = useTranslation();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (user?.user?.role === "ADMIN") {
      setIsAdmin(true);
    }
  }, [user]);

  const handleClick = (customer1: any) => {
    customerUpdate(customer1);
    sessionStorage.setItem("customerSelected", JSON.stringify(customer1));
  };

  const customerMenuItems: MenuProps["items"] =
    user?.customers?.length > 0
      ? [
          {
            key: "account-header",
            label: <span style={{ color: "#FFFFFF", marginBottom: 8 }}>{t("account").toUpperCase()}</span>,
            type: "group",
          },
          ...user?.customers?.map((customer2: any) => ({
            key: customer2.id,
            label: (
              <div className={`avatar-name-wrapper ${customer2.id === customer.id ? "selected" : ""}`}>
                <div className="custom-avatar">{customer2?.name ? customer2.name.charAt(0).toUpperCase() : "?"}</div>
                <span
                  onClick={() => handleClick(customer2)}
                  style={{
                    fontSize: "14px",
                    display: "block",
                    textAlign: "left",
                    borderRadius: collapsed ? "8px" : "4px",
                    height: "auto",
                    margin: "0",
                    justifyContent: collapsed ? "space-between" : "flex-start",
                    alignItems: collapsed ? "center" : "initial",
                    width: "100%",
                    cursor: "pointer",
                    color: "white",
                  }}
                >
                  {customer2?.name}
                </span>
              </div>
            ),
          })),
        ]
      : [];

  const menu = { items: customerMenuItems, className: "custom-dropdown-menu" };
  const MAX_LENGTH = 12;
  const getCustomerDisplayName = (name: string) => {
    if (!name) return "";
    return name.length > MAX_LENGTH ? name.slice(0, MAX_LENGTH) + "..." : name;
  };
  const getCustomerInitial = (name: string) => {
    return name ? name.charAt(0).toUpperCase() : "";
  };

  return (
    <Dropdown menu={menu} trigger={["click"]}>
      <Tooltip title={customer?.name || customer?.id} open={tooltipVisible} onOpenChange={(open) => setTooltipVisible(open)}>
        <div className={`${collapsed ? "collapsed-customer-dropdown" : "default-customer-dropdown"}`} onClick={() => setTooltipVisible(false)}>
          {collapsed ? getCustomerInitial(customer?.name || customer?.id) : getCustomerDisplayName(customer?.name || customer?.id)}
          {!collapsed && (
            <ChevronDown size={18}/>
          )}
        </div>
      </Tooltip>
    </Dropdown>
  );
};

export default CustomerDropdown;
